<template>
  <DetailTemplate
    :customClass="'service-detail detail-page'"
    v-if="getPermission('service:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0 py-3">
          <!-- <v-btn v-on:click="goBack" icon plain>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer> -->
          <div>
            <v-chip
              v-if="detail.activated == 1"
              class="mx-2"
              color="#66bb6a"
              label
              text-color="white"
            >
              Active
            </v-chip>
            <v-chip
              v-if="detail.activated == 0"
              class="mx-2"
              color="red"
              text-color="white"
              label
            >
              In-Active
            </v-chip>
          </div>
        </div>
        <div class="px-2 py-5 text-center">
          <ImageNew
            :images="detail.product_images"
            :parent="detail.id"
            type="service"
            :isPageLoading="pageLoading"
          ></ImageNew>
        </div>
        <div class="pt-0">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Basic Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Service #
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.barcode">
                  <v-chip
                    small
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white"
                    >{{ detail.barcode }}</v-chip
                  >
                </span>
                <em v-else class="text-muted"> no item code </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Service No.</td>
              <td class="font-size-18 py-1">
                <span
                  v-if="detail?.product_prefix || detail?.product_prefix_no"
                >
                  <v-chip
                    small
                    label
                    color="blue"
                    outlined
                    class="text-white text-capitalize"
                    >{{ detail?.product_prefix }}-{{
                      detail?.product_prefix_no
                    }}</v-chip
                  >
                  <showBarcode
                    v-if="false"
                    :barcodeUrl="detail.serial_image"
                    :barcode-text="detail.serial_no"
                    title="Barcode"
                  />
                </span>
                <em v-else class="text-muted"> no serial no </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Brand</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.brand">
                  <v-chip small outlined label color="blue">
                    {{ detail.brand }}
                  </v-chip>
                </span>
                <em v-else class="text-muted"> no brand </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Category</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.category">
                  <v-chip small outlined label color="blue">
                    {{ detail.category }}
                  </v-chip>
                </span>
                <em v-else class="text-muted"> no category </em>
              </td>
            </tr>

            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1">Reference no.</td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.ref_number">
                  <v-chip outlined label small color="blue">
                    {{ detail.ref_number }}
                  </v-chip>
                </span>
                <em v-else class="text-muted"> no Reference no. </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">
                Internal Service no.
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.int_service_number">
                  <v-chip outlined label small color="blue">
                    {{ detail.int_service_number }}
                  </v-chip>
                </span>
                <em v-else class="text-muted">no Internal Service</em>
              </td>
            </tr> -->
          </table>
          <table class="mt-5" width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Price Information
              </th>
            </tr>
            <!--  <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Unit Price(Selling)
              </td>
              <td class="font-size-18 py-1">
                <template v-if="dataLoading">
                  <v-skeleton-loader class="custom-skeleton" type="text">
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="dataLoading">
                    <v-skeleton-loader class="custom-skeleton" type="text">
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    {{ $accountingJS.formatMoney(detail.charges) }}
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Start Date</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.start_date">
                  {{ detail.start_date }}</span
                >
                <em v-else class="text-muted"> no start date</em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">End Date</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.end_date">
                  {{ detail.end_date }}</span
                >
                <em v-else class="text-muted"> no end date</em>
              </td>
            </tr>
            <tr>
              <td colspan="2"><v-divider></v-divider></td>
            </tr> -->
            <tr v-if="detail.custom_tax == 1">
              <td class="font-size-18 font-weight-500 py-1">Tax Name</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.tax_name" class="text-capitalize">
                  {{ detail.tax_name }}</span
                >
                <em v-else class="text-muted"> no tax name</em>
              </td>
            </tr>
            <tr v-if="detail.custom_tax == 1">
              <td class="font-size-18 font-weight-500 py-1">Tax Rate (%)</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.tax_rate">
                  {{ detail.tax_rate }}</span
                >
                <em v-else class="text-muted"> no tax rate</em>
              </td>
            </tr>
            <!--   <tr>
              <td class="font-size-18 font-weight-500 py-1">Purchase date</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.purchase_date_formatted">
                  {{ detail.purchase_date_formatted }}</span
                >
                <em v-else class="text-muted"> no purchase date</em>
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-700 py-1">Cost(Purchase)</td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.cost"> {{ detail.cost }}</span>
                <em v-else class="text-muted"> no Cost</em>
              </td>
            </tr> -->
            <tr>
              <template v-if="detail.product_type == 'service'">
                <td class="font-size-18 font-weight-500 py-1">UOM</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text-muted"> no UOM</em>
                </td>
              </template>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-2"
          >
            {{ detail.name }}
          </h1>
          <!-- <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3"
            style="font-size: 21px !important; font-weight: 700"
            >{{ detail.barcode }}</v-chip
          >
          <v-chip
            v-if="detail.activated == 1"
            class="mx-2"
            color="#66bb6a"
            label
            outlined
            text-color="green"
          >
            Active
          </v-chip>
          <v-chip
            v-if="detail.activated == 0"
            class="mx-2"
            color="red"
            outlined
            text-color="red"
            label
          >
            In-Active
          </v-chip> -->
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('service:update')">
        <v-skeleton-loader
          class="custom-skeleton d-inline-block"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <v-menu
          v-else
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in serviceMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('service:update')">
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          depressed
          :to="
            getDefaultRoute('service.update', {
              param: { id: service },
            })
          "
        >
          <v-icon size="20">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid class="px-0 pt-0">
        <v-tabs
          v-model="serviceTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              v-if="getPermission(tab.permission + ':view')"
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
              <span v-if="tab.title == 'Pricing'">
                ({{
                  detail.product_pricelogs
                    ? detail.product_pricelogs.length
                    : 0
                }})
              </span>
            </v-tab>
          </template>
        </v-tabs>
        <v-card class="mt-0 pb-4" elevation="0">
          <v-tabs-items v-model="serviceTab">
            <v-tab-item v-if="getPermission('service:view')" :value="'service'">
              <Overview :detail="detail"></Overview>
            </v-tab-item>
            <v-tab-item v-if="getPermission('service:view')" value="pricing">
              <ProductPriceListing
                type="pricing"
                :price-data="detail.product_pricelogs"
                :product="detail.id"
                @success="getProductDetail()"
              ></ProductPriceListing>
            </v-tab-item>
            <v-tab-item v-if="getPermission('service:view')" :value="'image'">
              <div class="service-detail-height">
                <Images
                  :images="detail.product_images"
                  :parent="detail.id"
                  type="service"
                  :isPageLoading="pageLoading"
                ></Images>
              </div>
            </v-tab-item>

            <v-tab-item v-if="getPermission('job:view')" :value="'job'">
              <div class="service-detail-search-height">
                <Tickets :detail="detail" type="service"></Tickets>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('quotation:view')"
              :value="'quotation'"
            >
              <div class="service-detail-search-height">
                <Quotations :detail="detail" type="service"></Quotations>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
              <div class="service-detail-search-height">
                <Invoices :detail="detail" type="service"></Invoices>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getPermission('history:view')" :value="'history'">
              <div class="service-detail-search-height">
                <InternalHistoryDetail
                  type="service"
                  :type_id="detail.id"
                ></InternalHistoryDetail>
              </div>
            </v-tab-item>

            <v-tab-item value="attachment">
              <AdditionalDocuments
                :type-id="detail.id"
                class="mx-4"
                type-text="Service Documents"
                :type="10"
                :extensive="false"
              ></AdditionalDocuments>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";

import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/service/detail/Overview";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import ImageNew from "@/view/pages/partials/Detail/ImageNew";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import showBarcode from "@/view/components/showBarcode.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIVE_FIELD_DATA } from "@/core/services/store/custom.fields.module.js";
import ProductPriceListing from "@/view/pages/product/detail/ProductPriceListing";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      dataLoading: null,
      detail: new Object(),
      /* serviceTab: "service", */
      serviceMoreAction: [
        /*  {
          title: "Duplicate",
          action: "duplicate",
          icon: "mdi-content-duplicate",
          disabled: false,
        }, */
        {
          title: "Mark as Active",
          action: "mark_as_active",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as In-Active",
          action: "mark_as_inactive",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "service",
          permission: "service",
          disabled: false,
        },
        {
          title: "Pricing",
          icon: "mdi-phone",
          key: "pricing",
          permission: "product",
          disabled: false,
        },
        /*     {
          title: "Images",
          icon: "mdi-phone",
          key: "image",
          permission: "product",
          disabled: false,
        }, */
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          permission: "product",
          disabled: false,
        },
        /*{
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },*/
        {
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        },
      ],
      formValid: true,
      formLoading: false,
      pageLoading: false,
      service: 0,
    };
  },
  components: {
    DetailTemplate,
    ImageNew,
    Images,
    Overview,
    Tickets,
    Quotations,
    Invoices,
    InternalHistoryDetail,
    AdditionalDocuments,
    ProductPriceListing,
    showBarcode,
  },
  methods: {
    patchProductParams(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: [_this.service],
          },
        })
        .then(() => {
          _this.getProductDetail();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.duplicateProduct();
          break;
        case "mark_as_active":
          _this.patchProductParams(1);
          break;
        case "mark_as_inactive":
          _this.patchProductParams(0);
          break;
      }
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("service.create", {
          query: {
            duplicate: this.service,
          },
        })
      );
    },
    getProductDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "service/" + _this.service,
        })
        .then(({ data }) => {
          _this.detail = data;
          if (Array.isArray(this.detail.custom_fields_service)) {
            this.$store.commit(
              SET_ACTIVE_FIELD_DATA,
              this.detail.custom_fields_service
            );
          }
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Service", route: "service" },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProductDetail();
    this.serviceTab = "service";
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service", route: "service" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.service = _this.$route.params.id;
    if (!_this.service || _this.service <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.serviceTab = _this.$route.query.tab;
    }
  },
  computed: {
    serviceTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (this.serviceTab != val) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "service";
      },
    },
  },
  // beforeDestroy() {
  // 	this.$store.dispatch(SET_BREADCRUMB, []);
  // },
};
</script>

<style>
#attachment .overflow-y {
  max-height: none !important;
}
</style>
